import { Layout } from '@components/layout';
import { withHtml } from '@components/logic';
import { Breadcrumbs, ResizedImage } from '@components/shared';
import { Contact } from '@components/standard';
import React, { useRef, useState, useContext } from 'react';

import {
    container,
    container__content,
    container__content__tags,
} from './styles/post.module.scss';

const Title = withHtml('h2');
const Content = withHtml('article');
const linkModifier = ({ slug }) => `/blog/${slug}/`;

const Post = ({ pageContext }) => {
    const { page, nextPost, prevPost, products } = pageContext;
    const { featuredImage, date, categories } = page;
    const postCategory = categories.nodes[0];
    console.log(page.tags);
    return (
        <Layout {...page}>
            <div className={container}>
                <Breadcrumbs
                    elements={[
                        {
                            label: 'Blog',
                            url: `/blog/`,
                        },
                    ]}
                />
                <div className={container__content}>
                    {featuredImage ? (
                        <ResizedImage {...featuredImage.node} size="600w" />
                    ) : (
                        null
                    )}
                    <h1>{page.title}</h1>
                    <div className={container__content__tags}>
                        {page.tags.nodes.map((tag, i) => (
                            <small key={i}>{tag.name}</small>
                        ))}
                    </div>
                    <Content>{page.content}</Content>
                </div>
                <Contact />
            </div>
        </Layout>
    );
};

export default Post;
